import { Inject, Injectable, Optional } from '@angular/core';
import { MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { parse } from 'date-fns';
import { LanguageService } from '.';
import { START_OF_WEEK } from './startOfWeek.token';

let SUPPORTS_INTL_API: boolean;
try {
  SUPPORTS_INTL_API = typeof Intl != 'undefined';
} catch {
  SUPPORTS_INTL_API = false;
}

/** Adapts the native JS Date for use with cdk-based components that work with dates. */
@Injectable()
export class LogicDateAdapter extends NativeDateAdapter {
  locale!: string;
  private _currentDateFormat = 'dd/MM/yyyy';

  get currentDateFormat() {
    return this._currentDateFormat;
  }

  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string,
    @Inject(START_OF_WEEK) private weekStart: number,
    private lang: LanguageService,
  ) {
    super(matDateLocale);
    this.lang.onLangChange.subscribe(() => {
      this.setLocale(this.lang.getLocale());
    });
  }

  getFirstDayOfWeek() {
    return this.weekStart || 1;
  }

  setLocale(str: string) {
    super.setLocale(str.toString());
    const testDate = new Date(2000, 11, 31);
    const format = new Intl.DateTimeFormat(str).format(testDate);
    this._currentDateFormat = format.replace('12', 'MM').replace('31', 'dd').replace('2000', 'yyyy');
  }

  parse(value: any): Date | null {
    if (typeof value === 'number') {
      return new Date(value);
    }
    return value ? parse(value, this._currentDateFormat, new Date(), { locale: this.lang.dateLocale }) : null;
  }

  format(date: Date, displayFormat: Record<string, unknown>): string {
    if (!this.isValid(date)) {
      throw Error('LogicDateAdapter: Cannot format invalid date.');
    }

    if (SUPPORTS_INTL_API) {
      // On IE and Edge the i18n API will throw a hard error that can crash the entire app
      // if we attempt to format a date whose year is less than 1 or greater than 9999.
      if (date.getFullYear() < 1 || date.getFullYear() > 9999) {
        date = this.clone(date);
        date.setFullYear(Math.max(1, Math.min(9999, date.getFullYear())));
      }

      displayFormat = { ...displayFormat, timeZone: 'utc' };

      const dtf = new Intl.DateTimeFormat(this.locale.toString(), displayFormat);
      return this._stripLTRRTL(this.formatInternal(dtf, date));
    }
    return this._stripLTRRTL(date.toDateString());
  }

  private _stripLTRRTL(str: string) {
    let val = str.replace(/[\u200e\u200f]/g, '');
    val = val
      .split('.')
      .map(s => this.addLeadingZero(s))
      .join('.');
    val = val
      .split('/')
      .map(s => this.addLeadingZero(s))
      .join('/');
    return val;
  }

  private addLeadingZero(str: string) {
    return str.length === 1 ? '0' + str : str;
  }

  /**
   * When converting Date object to string, javascript built-in functions may return wrong
   * results because it applies its internal DST rules. The DST rules around the world change
   * very frequently, and the current valid rule is not always valid in previous years though.
   * We work around this problem building a new Date object which has its internal UTC
   * representation with the local date and time.
   * @param dtf Intl.DateTimeFormat object, containing the desired string format. It must have
   *    timeZone set to 'utc' to work fine.
   * @param date Date from which we want to get the string representation according to dtf
   * @returns A Date object with its UTC representation based on the passed in date info
   */
  private formatInternal(dtf: Intl.DateTimeFormat, date: Date) {
    const d = new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds(),
      ),
    );
    return dtf.format(d);
  }
}
