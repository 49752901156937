import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectChange } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageService } from './language.service';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';

@Component({
  selector: 'lib-language-selector',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonToggleModule,
    MatSelectModule,
    TranslateModule,
    UpperCasePipe,
  ],
  template: `
    <mat-form-field *ngIf="flat !== true; else toggles">
      <mat-icon matPrefix *ngIf="withIcon">language</mat-icon>
      <mat-select [value]="value" (selectionChange)="setLanguage($event)" [disabled]="disabled" panelClass="selection">
        <mat-optgroup label="{{ 'Choose language' | translate }}" class="dialog-title">
          <mat-option *ngFor="let lang of availableLanguages" [value]="lang">
            {{ lang | uppercase }}
          </mat-option>
        </mat-optgroup>
      </mat-select>
      <ng-content></ng-content>
    </mat-form-field>

    <ng-template #toggles>
      <mat-button-toggle-group [value]="value" (change)="setLanguage($event)" [disabled]="disabled">
        <mat-button-toggle *ngFor="let lang of availableLanguages" [value]="lang">
          {{ lang | uppercase }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </ng-template>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        ::ng-deep .mat-form-field.mat-form-field-type-mat-select {
          max-height: 2.5rem;
          overflow: hidden;
        }
      }
      .dialog-title ::ng-deep .mat-optgroup-label {
        display: none;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent implements OnInit {
  _value!: string;
  @Input()
  set value(value: string) {
    this._value = value?.toLowerCase();
  }
  get value() {
    return this._value;
  }
  @Input() flat = false;
  @Input() disabled = false;
  @Input() withIcon = true;

  @Output() langChanged = new EventEmitter<string>();
  availableLanguages = this.language.supportedLanguages;

  constructor(private language: LanguageService) {}

  ngOnInit(): void {
    setTimeout(() => {
      if (!this.value) {
        // Set a default value
        this.setLanguage({ value: this.language.current } as MatSelectChange);
      }
    });
  }

  setLanguage($event: MatSelectChange | MatButtonToggleChange) {
    this.value = $event.value;
    this.langChanged.emit(this.value);
  }
}
